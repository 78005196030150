@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
html, body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans";
  font-size: 20px;
  width: 100%;
}

.open-sans.capitalized {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

#particlewave-canvas {
  background-color: #282828;
  position: fixed;
  width: 95%;
  max-width: 95%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -99999;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 3rem;
}

.top-bar {
  background-color: white;
  padding: 0.5rem 0.15rem;
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  z-index: 9999999;
  box-sizing: border-box;
}
.top-bar .menu {
  display: flex;
  flex-flow: row nowrap;
}
.top-bar .menu a {
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem 0.5rem;
  border-radius: 1rem;
  margin-right: 1rem;
  transition: 0.5s;
  text-decoration: none;
}
.top-bar .menu a:hover {
  background-color: #282828;
  color: white;
}

.content-wrapper {
  color: white;
  background: rgba(0, 0, 0, 0.27);
  width: 95%;
  max-width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre-line;
  overflow: hidden;
}
.content-wrapper .intro-section {
  width: 95%;
  max-width: 95%;
  margin-top: 200px;
  padding: 30px;
  text-shadow: 0 0 10px black;
}
.content-wrapper .intro-section .full-name {
  font-size: calc(36px + (90 - 36) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 600;
}
.content-wrapper .intro-section .full-name .purple {
  text-shadow: 3px 3px #938DF9;
}
.content-wrapper .intro-section .full-name .blue {
  text-shadow: 3px 3px #8BE9FD;
}
.content-wrapper .intro-section .full-name .green {
  text-shadow: 3px 3px #33DA7B;
}
.content-wrapper .intro-section .description {
  font-size: calc(20px + (37 - 20) * ((100vw - 320px) / (1280 - 320)));
  margin-top: 50px;
  max-width: 45%;
  font-weight: 450;
}
.content-wrapper .intro-section .description .pink {
  color: #F25069;
}
.content-wrapper .intro-section .description .green {
  color: #33DA7B;
}
.content-wrapper .intro-section .description .purple {
  color: #938DF9;
}
.content-wrapper .intro-section .description .blue {
  color: #8BE9FD;
}
.content-wrapper .intro-section .description .orange {
  color: #F2995A;
}
.content-wrapper .intro-section .description .yellow {
  color: #E6D478;
}
.content-wrapper .intro-section .description .red {
  color: #FF5555;
}
.content-wrapper .intro-section .socials {
  margin-top: 50px;
  max-width: 45%;
  min-width: 275px;
  margin-bottom: 30px;
}
.content-wrapper .intro-section .socials .social-button {
  color: black;
  background-color: white;
  font-size: 0.7rem;
  display: inline-block;
  padding: 0.35rem 0.7rem;
  border-radius: 1rem;
  text-shadow: none;
  text-decoration: none;
  margin-right: 15px;
  transition: 0.5s;
  margin-bottom: 15px;
}
.content-wrapper .intro-section .socials .social-button:hover {
  transform: scale(1.2, 1.2);
}
.content-wrapper .intro-section .socials .social-button .text {
  margin-left: 5px;
}
.content-wrapper .portfolio-section {
  background-color: #fbfbfb;
  color: black;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-left: 1px solid #f9f9f9;
  border-right: 1px solid #f9f9f9;
}
.content-wrapper .portfolio-section .title {
  width: 100%;
  padding: 15px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}
.content-wrapper .portfolio-section .grid {
  margin-top: 15px;
  padding: 15px 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.content-wrapper .portfolio-section .grid .project {
  min-width: 225px;
  max-width: 100%;
  max-width: 300px;
  width: 30%;
  position: relative;
  margin-bottom: 15px;
  margin-right: 20px;
  margin-left: 20px;
}
.content-wrapper .portfolio-section .grid .project img {
  min-height: 225px;
  max-width: min(100%, 300px);
  aspect-ratio: 1;
  border-radius: 100%;
}
.content-wrapper .portfolio-section .grid .project a {
  display: block;
  width: 100%;
  height: 100%;
}
.content-wrapper .portfolio-section .grid .project img.display-none {
  display: none;
}
.content-wrapper .portfolio-section .grid .project #particles-js, .content-wrapper .portfolio-section .grid .project #matrix-js {
  border-radius: 100%;
  overflow: hidden;
  aspect-ratio: 1;
}
.content-wrapper .portfolio-section .grid .project #particles-js canvas, .content-wrapper .portfolio-section .grid .project #matrix-js canvas {
  min-height: 225px;
  max-width: 100%;
  height: auto;
  aspect-ratio: 1;
}
@media screen and (min-width: 350px) {
  .content-wrapper .portfolio-section .grid .project #particles-js canvas, .content-wrapper .portfolio-section .grid .project #matrix-js canvas {
    max-width: 300px;
  }
}
.content-wrapper .portfolio-section .grid .project #particles-js {
  background: linear-gradient(135deg, #09385d, #6d1010);
}
.content-wrapper .portfolio-section .grid .project #matrix-js {
  background-color: black;
}
.content-wrapper .portfolio-section .grid .project .text {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  color: transparent;
  border-radius: 100%;
  transition: 0.5s;
  text-align: center;
}
.content-wrapper .portfolio-section .grid .project .text:hover {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}
.content-wrapper .portfolio-section .grid .project .text .tech-stack {
  font-size: 0.6rem;
}
.content-wrapper .portfolio-section .grid .project .text .tech-stack svg {
  margin-right: 5px;
}
.content-wrapper .portfolio-section .grid .project .text .description {
  font-size: 0.8rem;
}

.blog-section {
  padding: 30px;
}
.blog-section .title {
  text-align: center;
  margin-bottom: 30px;
}
.blog-section .blog-tiles {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: start;
  overflow: hidden;
  cursor: pointer;
}
.blog-section .blog-tiles .blog-tile {
  flex-grow: 1;
  flex-basis: 30%;
  max-width: 30%;
  min-width: 250px;
  height: auto;
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 15px;
  position: relative;
}
.blog-section .blog-tiles .blog-tile:hover:before {
  opacity: 0.3;
  transition: linear 0.3s;
}
.blog-section .blog-tiles .blog-tile:hover .content {
  opacity: 1;
  transition: linear 0.3s;
}
.blog-section .blog-tiles .blog-tile:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
}
.blog-section .blog-tiles .blog-tile img {
  max-width: 100%;
}
.blog-section .blog-tiles .blog-tile .content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  opacity: 0;
  padding: 1rem;
  overflow: hidden;
  box-sizing: border-box;
}
.blog-section .blog-tiles .blog-tile .content .title {
  text-align: center;
}
.blog-section .blog-tiles .blog-tile .content .date {
  font-size: 0.6rem;
}
.blog-section .blog-tiles .blog-tile .content .summary {
  font-size: 0.8rem;
}
.blog-section .blog-content {
  max-width: 100%;
  overflow: hidden;
  margin-top: 15px;
}
.blog-section .blog-content img {
  max-width: 100%;
  margin: 15px 0 15px 0;
}
.blog-section .blog-content iframe {
  max-width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}
.blog-section .blog-content .collapsible-blog {
  display: none;
}
.blog-section .blog-content .collapsing-blog {
  transform: scaleY(0);
  transition: transform 0.5s ease-in, opacity 0.5s ease-in;
  transform-origin: top;
  opacity: 0;
  display: block;
}
.blog-section .blog-content .expanding-blog {
  transform: scaleY(1);
  transition: transform 0.5s ease-in, opacity 0.5s ease-in;
  transform-origin: top;
  opacity: 1;
}
.blog-section .blog-content .hidden-blog {
  display: none;
}
.blog-section .blog-content .active-blog {
  display: block;
}

